import { keyColumn } from "@upkeeplabs/linq";

export class ZipCodeCoverageDetails {
    zip: string;
    @keyColumn() primaryCity: string;
    @keyColumn() state: string;
    @keyColumn() county: string;
    @keyColumn() coordinates: string;
    @keyColumn() currentActive: number;
    @keyColumn() claimsLast12: number;
    @keyColumn() contractorsJson: string;

    private _contractors: ContractorListItem[];
    get contractors() {
        if (!this._contractors && this.contractorsJson) { 
            this._contractors = JSON.parse(this.contractorsJson); }
        return this._contractors;
    }
    set contractors(value: ContractorListItem[]) {
        this._contractors = value;
        this.contractorsJson = JSON.stringify(value);
    }

    private _coordinatesArray: any[];
    get coordinatesArray() { 
        if (!this._coordinatesArray) { 
            this._coordinatesArray = JSON.parse(this.coordinates); }
        return this._coordinatesArray;
    }


}

export class ContractorListItem {
    name: string;
    tierName: string;
    inactive: boolean;
    sort: string;
    id: string;
}