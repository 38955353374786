import { Component, Input, SimpleChanges, Output, EventEmitter, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PlanClient, PlanGridModel } from '@cogent/client/shared/models/plans/plan-client.model';
import { ApiService } from '@cogent/client/api';
import { ViewCoverageDialogComponent } from '@cogent/client/shared/components/plans-and-coverage/view-coverage/view-coverage.component';
import { Address } from '@upkeeplabs/models/cogent';




@Component({
    templateUrl: './plan-selection.component.html',
    styleUrls: ['./plan-selection.component.css'],
    selector: 'app-plan-selection',
})
export class PlanSelectionComponent implements OnChanges {

    @Input() plans: PlanClient[];
    @Input() selectedPlan: PlanClient;
    @Input() useMonthly: boolean;
    @Output() selectedPlanChange: EventEmitter<PlanClient>;
    @Output() useMonthlyChange: EventEmitter<boolean>;
    @Input() highlightedPlan: PlanClient;
    @Input() removeOneTimeServices = false;
    
    @Input() address: Address;

    mobileCompareVisible = false;
    currentMobileCompareSlide = 0;
    planGrid: PlanGridModel;



    constructor(public dialog: MatDialog) {
        this.selectedPlanChange = new EventEmitter();
        this.useMonthlyChange = new EventEmitter();
    }
    compareMobile() {
        this.mobileCompareVisible = !this.mobileCompareVisible;

        if (this.mobileCompareVisible) {
            this.updateWidths();
        }
    }

    get highlightIndex() {
        if (!this.highlightedPlan || !this.plans) {
            return -1;
        }

        return this.plans.indexOf(this.highlightedPlan);
    }

    updateWidths() {
        setTimeout(() => {
            const width = document.getElementById('measureContainer').clientWidth / 2;
            const elements = document.getElementsByClassName('compare-column');
            for (let i = 0; i < elements.length; i++) {
                const element: any = elements[i];
                element.style.width = width + 'px';
            }

            const buttonLoc = (window.innerHeight - document.getElementById('measureContainer').getBoundingClientRect().top) / 2;

            document.getElementById('comparePrev').style.top = buttonLoc + 'px';
            document.getElementById('compareNext').style.top = buttonLoc + 'px';
        });
    }

    updateWidthsAndPerserveScroll() {
        this.updateWidths();
        this.currentMobileCompareSlide = this.mobileCompareSlides;
    }

    getSampleContractUrl(item: PlanClient) {
        return ApiService.endPointNode + `Plan/contract/pdf/${item.id}`;
    }

    get mobileCompareSlides(): number {
        if (!this.planGrid) {
            return 0;
        }

        let num = Math.ceil((this.selectedPlans.length + 1) / 2);
        if (this.selectedPlans.length % 2 === 0) {
            num--;
        }
        return num;
    }

    get mobileSlideTranslate(): string {
        if (!document.getElementById('measureContainer')) {
            return;
        }

        const width = document.getElementById('measureContainer').clientWidth / 2;
        return 'translate(' + (-(this.currentMobileCompareSlide * width)) + 'px, 0px)';
    }

    removePlanComparisonItem(item: PlanClient) {
        item.selected = false;
    }

    nextCompareSlide() {
        if (this.currentMobileCompareSlide < this.mobileCompareSlides) {
            this.currentMobileCompareSlide++;
        }
    }

    prevCompareSlide() {
        if (this.currentMobileCompareSlide > 0) {
            this.currentMobileCompareSlide--;
        }
    }

    showCoverage(item) {
        this.dialog.open(ViewCoverageDialogComponent, { data: item.planItemId });
    }

    selectPlan(plan: PlanClient) {
        this.selectedPlan = plan;
        this.selectedPlanChange.emit(plan);
    }

    updateMonthly($event) {
        this.useMonthlyChange.emit($event);
    }

    get selectedPlans(): PlanClient[] {
        return this.planGrid.plans.filter(i => i.selected);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.plans) {
            this.planGrid = PlanGridModel.getPlanGridModel(this.plans);
            if (this.removeOneTimeServices) {
                const service = this.planGrid.categories.find(i => i.categoryName === 'Services');
                if (service) {
                    this.planGrid.categories.splice(this.planGrid.categories.indexOf(service), 1);
                }
            }
        }
    }

    emailSampleContract() {
        // this.planRepository.getContractBase64(plan.id).then(contractBase64 => {

        //   const emailArgs = new EmailArgs();
        //   emailArgs.attachments = [];
        //   const contractAttachment = new EmailAttachment();
        //   contractAttachment.contentType = 'application/pdf';
        //   contractAttachment.fileContents = contractBase64;
        //   contractAttachment.fileName = 'sample_contract_' + plan.name + '.pdf';
        //   emailArgs.attachments.push(contractAttachment);
        //   emailArgs.toAddresses = [];
        //   emailArgs.useSignature = true;

        //   this.dialog.open(EmailComposeModalComponent, { data: emailArgs });


        // });
    }

}
