
export class ClaimSummaryModel {
    expand: boolean;
    cancelledDate: Date;
    claimId: string;
    statusSort: number;
    linesJson: string;
    workOrderId: string;
    denialDate: Date;
    totalCashOuts?: number;
    totalContractorInvoices?: number;
    totalPurchaseOrders?: number;
    dateCompleted?: Date;

    constructor(
        public id: string = null, public createdDate: Date = null, public itemName: string = null, public contractorName: string = null,
        public dateClosed?: Date, public status = 'Active', public number: number = null, public type: string = null, public itemId: string = null
    ) {
    }

    get detailUrl(): string {
        return `/service/claim-detail/${this.id}`;
    }

    private _lines;
    get lines(): any[] {
        if (!this._lines) {
            if (this.linesJson) {
                this._lines = JSON.parse(this.linesJson)
            }
            else {
                this._lines = [];
            }
        }
        return this._lines;
    }
    set lines(value: any[]) {
        this._lines = value;
    }
}

export class GroupedClaimsModel {

    public static fromClaims(claims: ClaimSummaryModel[]): GroupedClaimsModel[] {

        let results: GroupedClaimsModel[] = [];

        claims.forEach(claim => {
            let result = results.filter(r => r.status === claim.status)[0];
            if (!result) {
                result = new GroupedClaimsModel(claim.status,
                    claims.filter(i => i.status === claim.status), claim.statusSort);

                results.push(result);
            }
        });

        results.forEach(result => {
            result.claims.sort((a, b) => a.createdDate > b.createdDate ? -1 : 1);
        });


        // results = results.sort((a, b) => this.getStatusSort(a.status) > this.getStatusSort(b.status) ? 1 : -1);

        results = results.sort((a, b) => a.sort > b.sort ? 1 : -1);
        return results;
    }

    expanded = true;
    constructor(public status: string, public claims: ClaimSummaryModel[], public sort: number) { }

    private static getStatusSort(status: string): number {
        if (status === 'Pending') {
            return 0;
        }
        if (status === 'Active') {
            return .5;
        }
        if (status === 'Sent') {
            return 1;
        }
        if (status === 'Scheduled') {
            return 2;
        }
        if (status === 'Dispatched') {
            return 3;
        }
        if (status === 'Authorization Requested') {
            return 4;
        }
        if (status === 'Authorized') {
            return 5;
        }
        if (status === 'Parts Delay') {
            return 6;
        }
        if (status === 'Complete') {
            return 7;
        }
        if (status === 'Invoiced') {
            return 8;
        }
        if (status === 'Closed') {
            return 9;
        }
        if (status === 'Paid') {
            return 10;
        }

        if (status === 'Canceled') {
            return 100;
        }

        return 11;
    }
}

export class GroupedClaimsByYearModel {

    public static fromClaimItems(items: ClaimSummaryModel[]): GroupedClaimsByYearModel[] {
        const result: GroupedClaimsByYearModel[] = [];

        items.forEach(item => {
            let claimGroup = result.filter(i => i.year === item.createdDate.getFullYear())[0];
            if (!claimGroup) {
                claimGroup = new GroupedClaimsByYearModel(item.createdDate.getFullYear(), [item]);
                result.push(claimGroup);
            } else {
                claimGroup.items.push(item);
            }
        });

        result.sort((a, b) => a.year > b.year ? -1 : 1);
        result.forEach(r => {
            r.items.sort((a, b) => a.createdDate > b.createdDate ? -1 : 1);
        });

        return result;
    }

    static expandItems(items: GroupedClaimsByYearModel[], groupNumber = 0, expansionIndex = 0) {

        if (groupNumber < items.length) {
            if (expansionIndex < items[groupNumber].items.length) {

                items[groupNumber].items[expansionIndex].expand = true;
                if (expansionIndex < items[groupNumber].items.length - 1) {
                    setTimeout(() => GroupedClaimsByYearModel.expandItems(items, groupNumber, expansionIndex + 1), 100);
                } else if (groupNumber < items.length - 1) {
                    setTimeout(() => GroupedClaimsByYearModel.expandItems(items, groupNumber + 1, 0), 100);
                }
            }
        }
    }

    constructor(public year: number, public items: ClaimSummaryModel[]) { }
}
