import { Injectable } from '@angular/core';
import { ApiService } from '@cogent/client/api';
import { HttpClient } from '@angular/common/http';
import { ForecastModel } from '@cogent/shared/models/other/forecast.model';
import { PropertyMetaModel } from '@cogent/shared/models/common/property-meta.model';
import { Address } from '@upkeeplabs/models/cogent';

@Injectable({ providedIn: 'root' })
export class AddressApiService {
    getWeatherForecast(zipCode: string) {
        return this.api.getArrayNode(`address/forecast/${zipCode}`);
    }

    constructor(
        private api: ApiService,
        private http: HttpClient
    ) { }

    getForecast(addressId: string): Promise<ForecastModel[]> {
        return this.api.getArrayDotNet<ForecastModel>("Address/" + addressId + "/Forecast", null, () => new ForecastModel());
    }

    getForecastForPostalCode(postalCode: string): Promise<ForecastModel[]> {
        return this.api.getArrayDotNet<ForecastModel>(`Weather/${postalCode}`, null, () => new ForecastModel());
    }

    getPropertyMeta(streetAddress: string, postalCode: string): Promise<PropertyMetaModel> {
        return this.api.getSingleDotNet('PropertyMeta', {
            address: streetAddress,
            zip: postalCode
        });
    }

    getGoolePlaceDetail(placeId: string) {
        return this.api.getSingleNode(`Address/google-place-detail/${placeId}`);
    }

    doPostalCodeLookup(postalCode: string): Promise<Address> {
        const url = 'https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyDSnmr7NWncK530aptKRz2HG0cOhv3zeVs&address='
            + postalCode + '&sensor=true';

        return new Promise<Address>((resolve, reject) => {
            this.http.get(url).toPromise().then((results: any) => {
                const address = new Address();
                const result = results.results[0];

                address.postalCode = postalCode;

                for (const component of result.address_components) {
                    if (component.types.indexOf('locality') > -1) {
                        address.city = component.long_name;
                    }
                    if (component.types.indexOf('administrative_area_level_1') > -1) {
                        address.state = component.short_name;
                    }
                }

                resolve(address);
            });
        });
    }

    getAddress(addressId: string): Promise<Address> {
        return this.api.getSingleDotNet('Address/' + addressId, null, () => new Address());
    }

    getAddress2(addressId: string): Promise<Address> {
        return this.api.getSingleNode('Address/' + addressId, null, () => new Address());
    }

    saveAddress(address: Address): Promise<string> {
        return this.api.postIdNode('Address', address);
    }
}