import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class PromotionCode {
    @keyColumn() id: string;
    @column() code: string;
    @column() adjustmentId: string;
    @column() effectiveDate: Date;
    @column() expirationDate: Date;
    @column() oneTimeUse: boolean = false;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() appliesToRealEstate: boolean = false;
    @column() appliesToD2C: boolean = false;
    @column() appliesToRenewal: boolean = false;
    @column() internalOnly: boolean = false;
    @column() description: string;
    @column() marketingSourceId: string;
    @column() entityId: string;
    @column() maintenanceSerivceOfferings: string;
    @column() showInCogentWidget?: boolean;
    @column() isReferralCode?: boolean;
    @column() goodForMonths?: number;
}

// @dataSource() export class PromotionCodeSummary {
//     @keyColumn() id: string;
//     @column() adjustmentId: string;
//     @column() adjustmentItemId: string;
//     @column() code: string;
//     @column() adjustmentName: string;
//     @column() priceAdjustment: number;
//     @column() priceAdjustmentType: string;
//     @column() entityId: string;
//     @column() entityName: string;
// }