import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
@dataSource()
export class FeatureFlag {
    @keyColumn() id: string; @column() key: string;
    @column() enabled: boolean;
    @column() data: string;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() deletedDate: Date;
}