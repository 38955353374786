import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

type DwellingType = "Condo" | "SingleFamily" | "Duplex" | "Triplex" | "Fourplex" | "MultiFamily5Plus" | "Quadruplex" | "Mobile" | "Other" | "FivePlex" | "SixPlex";

@dataSource()
export class Address {
    @keyColumn() id: string;
    @column() address1: string;
    @column() address2: string;
    @column() city: string;
    @column() state: string;
    @column() postalCode: string;
    @column() latitude?: number;
    @column() longitude?: number;
    @column() yearBuilt?: number;
    @column() squareFeet?: number;
    @column() dwellingType?: DwellingType | string;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() createdDate?: Date;
    @column() lastModifiedDate?: Date;
    @column() deletedDate?: Date;
    @column() country: string;
    @column() propertyManagerId: string;
    @column() addressStandardized?: boolean = false;
    @column() addressStandardizeFailed?: boolean = false;
    @column() lotSquareFootage: number;
    @column() bathroomCount: number;
    @column() bedroomCount: number;
    @column() lawnSquareFootage: number;
    @column() yearBuiltValidated?: Date;

    constructor() {
        this.address1 = '';
        this.city = '';
        this.state = '';
        this.postalCode = '';
    }
    
    get isFilledOut(): boolean {
        return (this.address1 && this.city && this.state && this.postalCode) as any;
    }
}