import { Injectable } from '@angular/core';
import { WorkOrderSurvey } from '@cogent/shared/models/service/work-order-survey.model';
import { PolicySummary } from '@cogent/client/shared/models/policies/policy-summary.model';
import { ApiService } from '@cogent/client/api';
import { DataApiService } from '@cogent/client/shared/services/api/data-api.service';
import { ServiceApiService } from '@cogent/client/shared/services/api/service-api.service';
import { PolicyApiService } from '@cogent/client/shared/services/api/policy-api.service';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { NoteApiService } from '@cogent/client/shared/services/api/note-api.service';
import { WorkOrderSummaryClient } from '@cogent/client/shared/models/service/work-order-summary-client.model';
import { AppointmentTimeSlot } from '@cogent/client/shared/models/service/appointment-time-slot';
import { EntitySummary, InvoiceSummary, Note, WorkOrderPreferredTime, NoteDispatch } from '@upkeeplabs/models/cogent';
import { WorkOrderClient } from '@cogent/client/shared/models/service/work-order-client.model';
import { NoteAttachment } from '@cogent/client/shared/models/common/note-client.model';
import { PlanApiService } from '@cogent/client/shared/services/api/plan-api.service';

@Injectable({ providedIn: "root" })
export class ServiceRepositoryService {


    constructor(
        private api: ApiService,
        private planApi: PlanApiService,
        private noteApi: NoteApiService,
        private dataApi: DataApiService,
        private serviceApi: ServiceApiService,
        private policyApi: PolicyApiService
    ) { }

    getWorkOrderSummary(workOrderId: string): Promise<WorkOrderSummaryClient> {
        return this.api.getSingleDotNet<WorkOrderSummaryClient>("WorkOrderSummary/" + workOrderId, null, () => new WorkOrderSummaryClient());
    }

    getWorkOrderSummaryNoAuth(workOrderId: string) {
        return this.api.getSingleDotNet(`AnonymousClaimDetail/work-order-summary-by-id/${workOrderId}`, null, () => new WorkOrderSummaryClient());
    }

    requestFollowUpAppointment(id: string, timeSlots: { timeSlots: AppointmentTimeSlot[]; }) {
        return this.api.patchSingleDotNet(`AnonymousClaimDetail/request-follow-up-time-slots/${id}`, timeSlots);
    }

    requestInstallationPreferredTimes(id: string, timeSlots: AppointmentTimeSlot[]) {
        return this.api.patchNode(`purchasing/request-installation-time-slots/${id}`, timeSlots);
    }

    saveNoteDispatch(noteDispatch: NoteDispatch) {
        return this.api.postIdNode(`NoteDispatch`, noteDispatch);
    }

    saveNoteNoAuth(note: Note) {
        return this.api.postSingleDotNet(`AnonymousClaimDetail/save-note`, note);
    }

    stopAllJobPurchaseOrderWorflows(id: string) {
        return this.api.patchSingleDotNet(`WorkOrder/${id}/stop-all-workflows`, null);
    }

    getOnMyWayInfo(workOrderId: string): Promise<any> {
        return this.api.getSingleDotNet(`WorkOrder/${workOrderId}/on-my-way-info`);
    }

    getReviewGiftCardWasSent(policyId: string) {
        return this.api.getSingleNode(`policy/review-gift-card-was-offered/${policyId}`);
    }

    getAuthoProcess(workOrderItemId: string): Promise<string> {
        return this.api.getTextDotNet("AuthoProcess/" + workOrderItemId);
    }

    async saveAuthorizationAttachments(note: Note, attachments: NoteAttachment[]): Promise<any> {
        note.id = UtilitiesService.newid();
        await this.api.postVoidDotNet("Note", note);
        const promises = [];
        attachments.forEach(attachment => {
            attachment.noteId = note.id;
            promises.push(this.noteApi.saveNoteAttachment(note.id, attachment as any));
        });
        
        const noteDispatch = new NoteDispatch();
        noteDispatch.policyId = note.policyId;
        noteDispatch.workOrderId = note.workOrderId;
        noteDispatch.noteId = note.id;
        promises.push(this.saveNoteDispatch(noteDispatch));
        
        await Promise.all(promises);
    }

    getTechnicianCoordinates(workOrderId: string) {
        return this.api.getArrayDotNet(`WorkOrder/${workOrderId}/technician-coordinates`);
    }

    async getDistanceMatrix(source: any, dest: any) {
        const json = await this.api.getTextDotNet(`https://maps.googleapis.com/maps/api/distancematrix/json?units=imperial&origins=${source.latitude},${source.longitude}&destinations=${dest.latitude},${dest.longitude}&key=AIzaSyDemSdiDOSAT9tNd4ETjwv7m6QcCccopV0`);
        return JSON.parse(json);
    }

    getServiceFeeInvoice(claimId: string): Promise<InvoiceSummary[]> {
        return this.api.getArrayDotNet("InvoiceSummary", { claimId_eq: claimId }, () => new InvoiceSummary());
    }

    getWorkOrderProcess(workOrderItemId: string): Promise<string> {
        return this.dataApi.getWorkOrderProcess(workOrderItemId);
    }

    getWorkOrderHistory(workOrderId: string) {
        return this.serviceApi.getWorkOrderStatusHistory(workOrderId);
    }

    getAppointmentTimeSlots(workOrderItemId: string, includeToday = false): Promise<AppointmentTimeSlot[]> {
        return this.api.getArrayDotNet("AppointmentTimeSlot", { workOrderItemId, includeToday }, () => new AppointmentTimeSlot());
    }

    getWorkOrderPreferredTime(workOrderId: string): Promise<WorkOrderPreferredTime[]> {
        return this.api.getArrayDotNet(`WorkOrderPreferredTime`, { workOrderId_eq: workOrderId }, () => new WorkOrderPreferredTime());
    }


    getContractorSelection(policyId: string, workOrderItemId: string, brand: string, qAndA: string): Promise<any> {
        return this.serviceApi.getContractorSelection(policyId, workOrderItemId, brand, qAndA);
    }

    findPolicyByNumberAndPostalCode(policyNumber: number, postalCode: string, verificationCode: string, noAuth: boolean): Promise<any> {
        return this.api.getSingleDotNet("Policy/FindByNumberAndPostal", { policyNumber, postalCode, verificationCode, noAuth });
    }

    getFindByPhoneNumberVerificationCode(phoneNumber: string) {
        return this.api.getSingleDotNet(`Policy/send-find-verification-code/${phoneNumber}`);
    }

    getFindByEmailVerificationCode(email: string) {
        return this.api.getSingleDotNet(`Policy/send-find-verification-code-by-email/${email}`);
    }

    findPolicyByPhoneNumber(phoneNumber: string, verificationCode: string) {
        return this.api.getSingleDotNet(`policy/find-by-phone-number/${phoneNumber}/${verificationCode}`);
    }

    findPolicyByEmail(email: string, verificationCode: string) {
        return this.api.getSingleDotNet(`policy/find-by-email/${email}/${verificationCode}`);
    }

    findPolicyByAddressAndPostalCode(streetAddress: string, postalCode: string, verificationCode: string, noAuth: boolean): Promise<any> {
        return this.api.getSingleDotNet("Policy/FindByAddressAndPostal", { streetAddress, postalCode, verificationCode, noAuth });
    }

    findPolicyByNameAndPostalCode(name: string, postalCode: string, verificationCode: string, noAuth: boolean): Promise<any> {
        return this.api.getSingleDotNet("Policy/FindByNameAndPostal", { name, postalCode, verificationCode, noAuth });
    }

    getCoverageLanguage(planItemId: string): Promise<any> {
        return this.planApi.getPlanItemDetail(planItemId);
    }

    saveWorkOrderSurvey(survey: WorkOrderSurvey) {
        return this.api.postVoidDotNet(`registration/save-work-order-survey`, survey);
    }

    saveNodeWorkOrderSurvey(survey: WorkOrderSurvey) {
      return this.api.postSingleNode('work-order/save-work-order-survey', survey);
    }

    getUnAuthenticatedWorkOrderSummary(id: string) {
        return this.api.getSingleDotNet(`registration/get-work-order-summary/${id}`, null, () => new WorkOrderSummaryClient());
    }

    saveClaim(claim, errorHandler: (errors: any[]) => {} = null) {
        return this.serviceApi.saveClaimWithErrorResponse(claim, errorHandler);
    }

    saveWorkOrder(workOrder: WorkOrderClient) {
        return this.serviceApi.saveWorkOrder(workOrder);
    }

    async getPolicySummary(id: string): Promise<PolicySummary> {
        return this.policyApi.getPolicySummary(id);
    }


    getPolicyWorkOrderItems(policyId: string) {
        return this.policyApi.getPolicyWorkOrderItems(policyId);
    }

    getTenants(customerId: string): Promise<EntitySummary[]> {
        return this.api.getArrayDotNet<EntitySummary>("EntitySummary", { parentid_eq: customerId, type_eq: 'Tenant' }, () => new EntitySummary());
    }

    getCustomerSummary(customerId: string) {
        return this.api.getSingleDotNet<EntitySummary>("EntitySummary/" + customerId, () => new EntitySummary());
    }

    createWorkOrderHelpTask(workOrderId: string, policyId: string, message: string): Promise<void> {
        return this.api.postVoidDotNet("Task", {
            id: UtilitiesService.newid(),
            policyId,
            workOrderId,
            title: 'Service Request Help Request',
            description: message,
            taskQueueId: '4B05A4D0-A2C1-439F-9780-F9C8E9BE512E',
        });
    }
}
