import { keyColumn, column, dataSource } from "@upkeeplabs/linq";

@dataSource()
export class Policy {
    @keyColumn() id: string;
    @column() holderId: string;
    @column() policyNumber: number;
    @column() addressId: string;
    @column() serviceFeeAmount: number;
    @column() deletedDate?: Date;
    @column() planId: string;
    @column() previousPolicyId: string;
    @column() basePrice: number;
    @column() basePriceRecurring: number;
    @column() totalPrice: number;
    @column() totalPriceRecurring: number;
    @column() status: string;
    @column() effectiveDate: Date;
    // @column() version: timestamp;
    @column() lastModifiedDate?: Date;
    @column() createdDate?: Date;
    @column() totalExpense: number;
    @column() totalRevenue: number;
    @column() monthlyProcessingFee: number;
    @column() isMonthly: boolean;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() agentId: string;
    @column() closingCompanyId: string;
    @column() closingOfficerId: string;
    @column() coverageType: string;
    @column() canceledDate: Date;
    @column() estimatedClosingDate: Date;
    @column() escrowNumber: string;
    @column() totalPayments: number;
    @column() totalCashOuts: number;
    @column() totalContractorInvoices: number;
    @column() totalPurchaseOrders: number;
    @column() sellerAgentId: string;
    @column() initiatorType: string;
    @column() areaId: string;
    @column() monthsOfCoverage: number;
    @column() stripeCardId: string;
    @column() policyCanceledReasonId: string;
    @column() cancellationChanges: string;
    @column() accountExecutiveId: string;
    @column() doNotRenew: boolean;
    @column() promotionCodeId: string;
    @column() marketingSourceId: string;
    @column() totalPremiumPayments: number;
    @column() titleInvoiceAmount: number;
    @column() expirationDateOverride: Date;
    @column() salesRegionId: string;
    @column() contractPrintDate: Date;
    @column() transactionCoordinatorId: string;
    @column() alwaysSendDocumentsToHomeowner: boolean;
    @column() alwaysSendDocumentsToPropertyManager: boolean;
    @column() coHolderId: string;
    @column() creditCardExpirationDate: Date;
    @column() paymentAmountSyncDate: Date;
    @column() renewalFee: number;
    @column() altKey: string;
    @column() autoRenew: boolean;
    @column() renewalNote: string;
    @column() additionalTitleInvoiceEmails: string;
    @column() createdSource: string;
    @column() shortCode: string;
    @column() manuallySuspended: boolean;
    @column() billTo: string;
    @column() promotionalMonthsOfCoverage: number;
    @column() billableMonthsOfCoverage: number;
    @column() removedFromSearchIndexDate: Date;
    @column() lastContact?: Date;
    @column() mlsNumber?: string;
    @column() doNotRenewOffDate?: Date;
    @column() autoRenewOffDate?: Date;
    @column() commissionable?: boolean;
    @column() webQuoteId: string;
    @column() bundleAndSaveId: string;
    @column() autoRenewsToYearly?: boolean;
    @column() autoRenewCancelReasonId?: string;
    @column() autoRenewOffById?: string;
    @column() discountMonths?: number;
    @column() originalBasePrice?: number;
    @column() originalBasePriceRecurring?: number;
    @column() cancellationDeadline?: Date;

    coverageTypeEnum: string;
    creationResult?: any;
}